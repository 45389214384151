import * as React from "react";
import Header from "../../components/Header/Header";
import "./../../sass/main.scss";
import Footer from "../../components/Footer/Footer";
import JobMap from "../../components/JobMap";
import PageDetails from "../../components/PageDetails";

let isLocal = ["dev", "development"].includes(process.env.NODE_ENV);
let en_url = (isLocal ? "" : process.env.EN_URL) + "/en/";
const lang = process.env.LANG;
const isFrench = lang === "FR";

const FrIndexPage = () => {
  if (!isFrench) {
    return (
      <meta
        http-equiv="refresh"
        content={`0;url=${process.env.FR_URL}/fr/`}
        id="redirectTag"
      />
    );
  }

  return (
    <>
      <Header
        title={
          "Carrières dans nos bureaux du Canada à l’étranger | Affaires Mondiales Canada"
        }
        description={
          "Consulter toutes les opportunités d’emploi dans nos bureaux du Canada à l’étranger    "
        }
        lang={"FR"}
        langLink={en_url}
        bodyClasses={"page-fr"}
      />
      <main
        property="mainContentOfPage"
        resource="#wb-main"
        className="container pb-5 page-type-search page-search"
        typeof="WebPageElement"
      >
        <h1 className={""}>
          Opportunités d’emploi dans nos bureaux du Canada à l’étranger
        </h1>
        <p>
          Ce que nous faisons compte. Nous définissons, façonnons et faisons
          progresser les intérêts et les valeurs du Canada dans un environnement
          mondial complexe et en pleine évolution. Nous gérons les relations
          diplomatiques, faisons la promotion du commerce international et
          fournissons du soutien consulaire. Nous dirigeons les efforts
          internationaux en matière de développement, d’aide humanitaire et
          d’appui à la paix et à la sécurité. Nous contribuons également à la
          sécurité nationale et au développement du droit international.
        </p>
        <h2>Sur cette page</h2>
        <ul>
          <li>
            <a href={"#mappe"}>Carte d'emplois</a>
          </li>
          <li>
            <a href={"#frontieres"}>Une carrière sans frontières</a>
          </li>
          <li>
            <a href={"#engagement"}>Employés recrutés sur place</a>
          </li>
          <li>
            <a href={"#avis"}>Avis / Mises à jour</a>
          </li>
        </ul>
        <h2 id={isFrench ? "mappe" : "map"}>
          {isFrench ? "Rechercher les emplois" : "Find jobs"}
        </h2>
        <p>
          Effectuez une recherche par titre, pays, ville ou titre de programme
          pour voir les possibilités d’emploi dans nos bureaux canadiens à
          l’étranger.
        </p>
        <JobMap />

        <h2 id={"frontieres"}>Une carrière sans frontières</h2>
        <p>
          Faites partie d’une institution de classe mondiale. La fonction
          publique professionnelle et non partisane du Canada est parmi les
          meilleures au monde. Pour maintenir notre réputation, nous avons
          besoin de personnes talentueuses, compétentes et passionnées par leur
          travail.
        </p>

        <p>
          Faites carrière chez nous : une carrière à la mesure de votre passion
          qui compte vraiment et qui a un impact réel sur la vie des Canadiens.
          Contribuez à positionner le Canada dans le monde.
        </p>

        <p>
          Nous sommes un réseau mondial de professionnels qui travaillent dans
          plus de 175 bureaux à travers le monde. Les bureaux du Canada à
          l’étranger comprennent des employés canadiens et des employés recrutés
          sur place.
        </p>

        <p>
          <b>
            Nous travaillons ensemble pour renforcer les relations du Canada
            avec le monde.
          </b>
        </p>

        <h2 id={"engagement"}>Employés recrutés sur place (ERP)</h2>
        <div className={"container row"}>
          <div className={"col-md-6"} style={{ paddingLeft: 0 }}>
            <p>
              Les employés recrutés sur place (ERP) représentent une part
              importante de la main d’œuvre d’Affaires mondiales Canada.
            </p>
            <p>
              Ils sont nommés et employés à l’extérieur du Canada pour effectuer
              des tâches qui sont directement liées aux opérations des missions
              du gouvernement du Canada situées à l’extérieur du Canada. Ils
              appuient l’exécution des programmes et la prestation des services
              :
            </p>
            <ul>
              <li>du gouvernement du Canada</li>
              <li>des provinces</li>
              <li>d’autres partenaires à l’étranger</li>
            </ul>
            <p>
              Les ERP sont indispensables pour mener à bien chacun des mandats à
              l’étranger.
            </p>
          </div>
          <div className={"col-md-6"} style={{ paddingLeft: 0 }}>
            <p>
              Les ERP effectuent une grande variété de tâches, qui vont de
              travaux d’entretien à des services d’experts. Leur travail
              contribue au fonctionnement des missions canadiennes à l’étranger.
              Nous embauchons des ERP pour leurs connaissances :{" "}
            </p>
            <ul>
              <li>des langues locales</li>
              <li>du contexte local</li>
              <li>d’éléments opérationnels précis</li>
              <li>de réseaux</li>
            </ul>
            <p>
              Notre fonction publique bâtit une culture qui encourage
              l’innovation, les nouvelles idées, les approches novatrices, la
              collaboration accrue et la mobilisation renforcée. Faites une
              différence et contribuez à bâtir cette fonction publique moderne.
              Nous serons heureux de vous compter parmi nous!
            </p>
          </div>
          <h2 id={"avis"}>Avis / Mises à jour</h2>
          <p>
            À moins d’indication contraire dans l’avis d'offre d'emploi, le
            Canada ne parrainera pas d’autorisations de travail dans le cadre de
            ce processus de sélection. Si vous obtenez le poste, vous devez
            résider légalement dans le pays en question et avoir l’autorisation
            légale d’y travailler pour la durée du contrat.
          </p>
          <p />
          <p className="alert alert-info">
            Suite à la récente annonce du Gouvernement du Canada, la vaccination
            obligatoire contre la COVID-19 au sein des missions diplomatiques et
            consulaires du Canada à l’étranger est suspendue à compter du 20
            juin 2022. Le Gouvernement du Canada continuera d’évaluer le besoin
            de mesures de santé publique additionnelles, incluant la possible
            réintroduction d’un mandat vaccinal à une date ultérieure.
          </p>
          <p />
        </div>
        <PageDetails lang={"FR"} dateModified={"2022-01-05"} />
      </main>
      <Footer lang={"FR"} />
    </>
  );
};

export default FrIndexPage;
